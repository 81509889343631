import React, { useState, useEffect } from "react";
import axios from "axios";
import { Grid, Card, Typography } from "@mui/material";
import VuiBox from "components/VuiBox";
import VuiTypography from 'components/VuiTypography';
import linearGradient from "assets/theme/functions/linearGradient";
import colors from "assets/theme/base/colors";

function MatchCard({ match, type }) {
  const { gradients } = colors;
    // Функция для форматирования даты
    const formatDate = (dateStr) => {
    const date = new Date(dateStr.split("-").reverse().join("-"));
    const day = date.getDate();
    const month = date.toLocaleString("en", { month: "short" });
    return `${day} ${month}`;
};

  return (
    <Card
      sx={{
        height: '100%',
        background: linearGradient(gradients.cardDark.main, gradients.cardDark.state, gradients.cardDark.deg)
      }}
    >
      {/* <VuiBox mb={2} display="flex" justifyContent="space-between" width="100%" p={2}>
        {/* Заголовок */}
        {/* <VuiTypography color='white' fontWeight='bold'>
          {type === "single" ? "FREE SINGLE" : "FREE COMBO"}
        </VuiTypography>
      </VuiBox> */} 

      <VuiBox mb={2}>
        <VuiBox display="flex" justifyContent="space-between" width="100%">
          <VuiTypography color='white' variant='lg' fontWeight='bold' gutterBottom>
            {formatDate(match.date)} {match.time.slice(0, 5)}
          </VuiTypography>
          <VuiTypography color='white' variant='lg' fontWeight='bold' gutterBottom textAlign="right">
            Prediction: {match.prediction}
          </VuiTypography>
        </VuiBox>
        {/* Логотипы страны и лиги */}
        <VuiBox display="flex" justifyContent="space-between" width="100%" mb={1}>
        <VuiTypography color='white' variant='lg' fontWeight='bold' gutterBottom>
          {match.match_country_name}
        </VuiTypography>
        <VuiTypography color='white' variant='lg' fontWeight='bold' gutterBottom>
           {match.league_name}
        </VuiTypography>
          {/* <img src={match.match_country_image_path} alt="Country Logo" style={{ width: "30px" }} />
          <img src={match.league_logo_path} alt="League Logo" style={{ width: "30px" }} /> */}
        </VuiBox>
      </VuiBox>

      <VuiBox display="flex" justifyContent="space-between" width="100%" mb={2}>
        <VuiBox display="flex" flexDirection="column" alignItems="center" width="100%">
          <img src={match.localTeam_logo} alt="Local Team Logo" style={{ width: "70px" }} />
          <VuiTypography color='white' variant='lg' fontWeight='bold' gutterBottom>
            {match.localTeam}
          </VuiTypography>
        </VuiBox>
        <VuiBox display="flex" flexDirection="column" alignItems="center" width="100%">
          <img src={match.visitorTeam_logo} alt="Visitor Team Logo" style={{ width: "70px" }} />
          <VuiTypography variant="body2" color="white">
            {match.visitorTeam}
          </VuiTypography>
        </VuiBox>
      </VuiBox>

      {/* Дополнительная информация */}
      {/* <VuiTypography color='white' variant='lg' fontWeight='bold' gutterBottom>
        Status: {match.status}
      </VuiTypography>
      <VuiTypography color='white' variant='lg' fontWeight='bold' gutterBottom>
        Scores: {match.rez_1_score} - {match.rez_2_score} - {match.rez_3_score}
      </VuiTypography> */}
    </Card>
  );
}

function MatchesDisplay() {
  const [matches, setMatches] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchMatches = async () => {
      try {
        const response = await axios.post(
          "https://total93admin.com/get_prediction_list",
          { type: "all" },
          {
            headers: {
              Authorization: 'Bearer =TxcSDFCXVRGVESFSFRgrs34fsdfsd', // Замените токен на актуальный
            },
          }
        );

        if (response.data && response.data.events) {
          const singleMatch = response.data.events.find(event => event.type === "single");
          const comboMatch = response.data.events.find(event => event.type === "combo");
          setMatches([singleMatch, comboMatch]);
        } else {
          setMatches([]);
        }
      } catch (error) {
        setError("Failed to load matches.");
      } finally {
        setLoading(false);
      }
    };

    fetchMatches();
  }, []);

  if (loading) return <p>Loading matches...</p>;
  if (error) return <p>{error}</p>;
  if (!matches.length) return <p>No matches available.</p>;

  return (
    <VuiBox>
      <Grid container spacing={3}>
        {matches.map((match, index) =>
          match ? (
            <Grid item xs={12} md={6} key={index}>
              <MatchCard match={match} />
            </Grid>
          ) : (
            <Grid item xs={12} md={6} key={index}>
              <Card sx={{ p: 2 }}>
                <Typography variant="h6" color="white">
                  No match available, come back tomorrow...
                </Typography>
              </Card>
            </Grid>
          )
        )}
      </Grid>
    </VuiBox>
  );
}

export default MatchesDisplay;
