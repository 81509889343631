import React from 'react';
// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

function Footer() {
  return (
    <VuiBox
      display="flex"
      flexDirection={{ xs: "column", lg: "row" }}
      justifyContent="space-between"
      direction="row"
      component="footer"
      py={2}
      pb={0}
    >
      <VuiBox item xs={12} sx={{ textAlign: "center" }}>
        <VuiTypography
          variant="button"
          sx={{ textAlign: "center", fontWeight: "400 !important" }}
          color="white"
        >
          {/* FootRoll @ 2024, Made with Ai Predictions Model by {" "} */}
          FootRoll @ 2024, Made with Ai Predictions Model "Copyright @ All Rights Reserved"
          {/* <VuiTypography
            component="a"
            variant="button"
            href="https://simmmple.com/"
            sx={{ textAlign: "center", fontWeight: "500 !important" }}
            color="white"
            mr="2px"
          >
            Simmmple
          </VuiTypography>
          &
          <VuiTypography
            ml="2px"
            mr="2px"
            component="a"
            variant="button"
            href="https://www.creative-tim.com/"
            sx={{ textAlign: "center", fontWeight: "500 !important" }}
            color="white"
          >
            Creative Tim
          </VuiTypography>
          for a better web */}
        </VuiTypography>
      </VuiBox>
      <VuiBox item xs={10}>
        <VuiBox display="flex" justifyContent="center" flexWrap="wrap" mb={3}>
          <VuiBox mr={{ xs: "20px", lg: "26px" }}>
            <VuiTypography
              component="a"
              href="https://t.me/footrollcommunity"
              variant="body2"
              color="white"
            >
              Community
            </VuiTypography>
          </VuiBox>
          <VuiBox mr={{ xs: "20px", lg: "26px" }}>
            <VuiTypography
              component="a"
              href="https://x.com/footrollbot?s=11"
              variant="body2"
              color="white"
            >
              Enjoy social's
            </VuiTypography>
          </VuiBox>
          {/* <VuiBox>
            <VuiTypography
              component="a"
              href="https://total93.gitbook.io/foot-roll-ai"
              variant="body2"
              color="white"
            >
              More info
            </VuiTypography>
          </VuiBox> */}
          <VuiBox>
            <VuiTypography
              component="a"
              href="https://t.me/FootRoll_bot"
              variant="body2"
              color="white"
            >
              Get started
            </VuiTypography>
          </VuiBox>
        </VuiBox>
      </VuiBox>
    </VuiBox>
  );
}

export default Footer;
